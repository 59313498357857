/* eslint-disable */
import HttpService from '../HttpService'
import $auth from "@/common/auth";

export default class CaseService {
  constructor() {
    this._httpService = new HttpService(`odoo`);
  }

  async FindAll() {
    return await this._httpService.get('/case');
  }
  async FindById(id) {
    return await this._httpService.get('/case/findAllWithoutCheckPermissions', { id });
  }

  async FindByParam(path) {
    return await this._httpService.get('/case' + path);
  }

  async FindAllByParam(path) {
    return await this._httpService.get('/case/findAllWithoutCheckPermissions' + path);
  }

  async Create(data) {
    return await this._httpService.post('/case', data);
  }

  async CreateCaseWithAttachment(data) {
    return await this._httpService.post('/case/case_with_attachment', data);
  }

  async CreateCaseEvent(data) {
    return await this._httpService.post('/case/case_event', data);
  }

  async FindLimiteDateExternalProtocol() {
    return await this._httpService.get('/case/find_limite__date_external_protocol');
  }

  async FindbyDescribe() {
    if (!(sessionStorage.getItem("describe-case"))) {
      var describe = await this._httpService.get('/case/describe');
      sessionStorage.setItem('describe-case', JSON.stringify(describe.data));
    }
  }

  async countCaseTotals(){
    const result = {
      pendingCases: 0,
      openedCases: 0,
      totalCases: 0
    }

    let beneficiaries = $auth.user().beneficiaries || $auth.user().dependents;

    if(!beneficiaries){
      return result;
    }

    const params = [];
    beneficiaries.forEach(beneficiary =>{
      if (!params.find(p => (p.beneficiaryId === beneficiary.beneficiaryID) )){
        params.push({
          label: 'beneficiaryId',
          value: beneficiary.beneficiaryId || beneficiary.dependentId,
        })
      }
    })

    const responses = await this._httpService.all('/case/countCaseTotalsByBeneficiaryId', params);
    responses.forEach(response =>{
      result.pendingCases += response.data.pendingCases;
      result.openedCases += response.data.openedCases;
      result.totalCases += response.data.totalCases;
    })

    return result;
  };

  async findAllClosedCases(){
    return this.findAllCasesByServiceURL('/case/findAllClosedCasesByBeneficiaryId');
  };


  async findAllOpenedCases(){
    return this.findAllCasesByServiceURL('/case/findAllOpenedCasesByBeneficiaryId');
  };

  async findAllCasesByServiceURL(path){

    const result =[];

    let beneficiaries = $auth.user().beneficiaries || $auth.user().dependents;

    if(!beneficiaries){
      result
    }
    const params = [];
    beneficiaries.forEach(beneficiary =>{
      if (!params.find(p => (p.beneficiaryId === beneficiary.beneficiaryID) )){
        params.push({
          label: 'beneficiaryId',
          value: beneficiary.beneficiaryId || beneficiary.dependentId,
        })
      }
    })

    const responses = await this._httpService.all(path, params);
    responses.forEach(response =>{
      result.push(...response.data.records);
    })

    return result;
  }

}
