/* eslint-disable */
import HttpService from '../HttpService'

export default class ContentVersionService {
  constructor() {
    this._httpService = new HttpService(`odoo`);
  }

  async FindAll(documentid, modelName) {
    let params = {
      resourceId: documentid,
      resourceModel: modelName
    }

    return await this._httpService.get('/content_version', params);
  }

  async FindById(Id) {
    return await this._httpService.get('/content_version', { Id });
  }

  async getDownloadFile(id) {
    let url = '/content_version/' + id + '/download'
    return await this._httpService.getBlob(url);
  }
}
