<template>
  <v-container style="background-color: white; height: 100vh" fluid>
    <v-row class="pb-3">
      <v-col cols="12">
        <BackArrow :route="'atendimentos'"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-card elevation="5" rounded="lg">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h2 style="color: #532E88;">Detalhes</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field class="pb-4" label="Solicitação" v-model="item.solicitation" dense readonly></v-text-field>
                <v-text-field class="pb-4" label="Protocolo" v-model="item.protocol" dense readonly></v-text-field>
                <v-text-field v-if="item.externalProtocol" :label="`Protocolo - Atendimentos abertos até ${limiteDateExternaProtocol}`"
                              v-model="item.externalProtocol" class="pb-4" dense readonly>
                </v-text-field>
                <v-text-field class="pb-4" label="Status" v-model="item.status" dense readonly></v-text-field>
                <v-text-field class="pb-4" label="Benefício / Operadora" v-model="item.benefit" dense readonly></v-text-field>
                <v-text-field class="pb-4" label="Abertura" v-model="item.createDate" dense readonly></v-text-field>
                <v-text-field class="pb-4" label="Atendido" v-model="item.attendee" dense readonly></v-text-field>
                <v-textarea auto-grow rows="1" row-height="15" label="Descrição" v-model="item.description" dense readonly></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="6">
        <TimeLine :caseId="id" :externalProtocol="externalProtocol" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CaseService from "../../services/odoo/CaseService";
import ContractService from "../../common/ContractService"
import BackArrow from '@/components/covid/back-arrow/index'
import TimeLine from '@/components/case/TimeLine'
import Vue from "vue";

export default {
  name: 'AtendimentoDetalhar',
  components: {
    BackArrow,
    TimeLine
  },
  data: () => ({
    id: null,
    externalProtocol: null,
    limiteDateExternaProtocol: '',
    item: {
      arquivos: [],
      comentarios: [],
    }
  }),
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    ...mapMutations(["showmsg"]),
    requestDetails() {
      this._caseService.FindById(this.id).then(response => {
        this.configureItem(response.data.records[0]);
      }).catch(() => {
        this.showmsg({
          text:  "Erro ao carregar informações",
          type: "error",
        });
      }).finally(() => {});
    },
    configureItem(itemData) {
      let benefit = itemData.xipp_contract_id && itemData.xipp_contract_id.benefit_id ?
          itemData.xipp_contract_id.benefit_id : "";

      benefit += itemData.xipp_contract_id && itemData.xipp_contract_id.carrier_id ?
          " / " + itemData.xipp_contract_id.carrier_id.xipp_commercial_name : "";

      let contract = itemData.xipp_contract_id && itemData.xipp_contract_id.policy ? itemData.xipp_contract_id.policy : "";
      contract += " - " + (itemData.xipp_contract_id && itemData.xipp_contract_id.name ? itemData.xipp_contract_id.name : "");

      this.item = {
        ...this.item,
        protocol: itemData.id,
        externalProtocol: itemData.xipp_external_protocol ? itemData.xipp_external_protocol : null,
        createDate: Vue.filter('formatDate')(itemData.create_date),
        attendee: itemData.xipp_attendee_name ? itemData.xipp_attendee_name : (itemData.suppliedName ? itemData.suppliedName : null),
        solicitation: itemData.name,
        status: itemData.stage_id,
        description: itemData.description,
        resolution: itemData.xipp_resolution,
        contract,
        benefit
      };
    },
    async loadLimiteDateExternalProtocol() {
      await this._caseService.FindLimiteDateExternalProtocol()
          .then((response) => {
            if(response  && response.data) {
              this.limiteDateExternaProtocol = response.data;
            }
          });
    },
  },
  async beforeMount(){
    this.id = this.$route.params.id;
    this.externalProtocol = this.$route.params.externalProtocol;
    this._caseService = new CaseService();
    this._contractservice = new ContractService();
    await this.loadLimiteDateExternalProtocol();
    await this.requestDetails();
  },
};
</script>

<style scoped>

</style>
