/* eslint-disable */
import HttpService from '../HttpService'

export default class ComentsService {
  constructor() {
    this._httpService = new HttpService(`odoo`);
  }

  async FindById(id, model, type, subtype) {
    return await this._httpService.get('/custom_case_comment', { model: model, documentId: id, type: type, subTypeName: subtype});
  }

  async Create(data) {
    return await this._httpService.post('/custom_case_comment', data);
  }
}
