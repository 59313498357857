/* eslint-disable */
import HttpService from '../services/HttpService';
export default class ContractService {
  constructor() {
    this._httpService = new HttpService(`salesforce`);
  }

  async FindbyDescribe() {
    if (!(sessionStorage.getItem("describe-contract"))) {
      var describe = await this._httpService.get('/contract/describe');
      sessionStorage.setItem('describe-contract', JSON.stringify(describe.data));
    }
  }

}
