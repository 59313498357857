<template>
  <v-container fluid class="pa-0">
    <v-card elevation="5" rounded="lg">
      <v-card-title class="primary--text">
        <h3 style="color: #532E88;">Eventos</h3>
      </v-card-title>

      <v-card-text v-if="!timeLine.length">Nenhum registro neste atendimento!</v-card-text>

      <v-card-text class="pl-0" v-else>
        <v-timeline
          dense
        >
          <v-timeline-item
                  v-for="(item, index) in timeLine"
                  :key="item.id"
                  color="primary"
                  :left="isClientItem(item) ? false : true"
                  :right="isClientItem(item) ? true : false"
                  fill-dot
                  :hide-dot="!showDate(item, index)"
                  large
          >

              <template v-slot:icon>
                  <span v-if="showDate(item, index)" class="white--text">{{item.formatDate}}</span>
              </template>

            <v-row>
                <v-col class="px-0 py-4 pr-3">
                    <v-card :color="isClientItem(item) ? clientCardColor : brokerCardColor " @click="item.hasMoreThanOneLine ? item.showMore = !item.showMore : ' ' ">
                      <v-card-text v-if="item.type === 'comment'" v-show="item.text !== ''" :class="'pb-0' + (isClientItem(item) ? ' white--text ' : ' black-text ')">
                          <v-expand-transition v-bind="item.commentFirstLine">
                            <div v-show="!item.showMore" v-html="item.hasMoreThanOneLine ? getFirstLineComment(item.text) : getCommentText(item.text)" class="showOnlyFirstLine"/>
                          </v-expand-transition>
                          <v-expand-transition v-bind="item.commentFull">
                            <div v-show="item.showMore" v-html="item.text" class="showFullContent"/>
                          </v-expand-transition>
                        <v-row>
                          <v-col :class="'pa-0' + (item.hasMoreThanOneLine  ? ' ' : ' d-none ')" align="right">
                            <v-btn small icon>
                              <div style="text-transform: initial" :class="(isClientItem(item) ? ' white--text ' : ' black-text ')" >
                                {{ item.showMore ? 'Exibir menos' : 'Exibir mais' }}
                              </div>
                              <v-icon :class="(isClientItem(item) ? ' white--text ' : ' black-text ')">
                                {{ item.showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                              </v-icon>
                            </v-btn>
                          </v-col>
                          <v-col class="pa-0 mb-1" align="right">
                            {{item.formatHour}}
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-card-text v-else :class="'pb-0' + (isClientItem(item) ? ' white--text ' : ' black-text ')" @click="downloadFile(item)" style="cursor: pointer">
                        <v-row>
                          <v-col class="pt-0" cols="12">
                            <v-icon :class="'mr-1' + (isClientItem(item) ? ' white--text ' : ' black-text ')" >{{ item.icon }}</v-icon>
                            {{ item.text }}
                          </v-col>
                          <v-col class="pa-0 mb-1"  cols="12" align="right">
                            {{item.formatHour}}
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>

    <v-speed-dial
        v-model="fab"
        bottom
        right
        fixed
    >
      <template v-slot:activator>
        <v-btn
            v-model="fab"
            color="primary"
            dark
            fab
            @click="$router.push(`/atendimento/${caseId}/addEvent`)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>


    </v-speed-dial>
  </v-container>
</template>

<script>
import moment from "moment";
import ComentsService from "@/services/odoo/ComentsService";
import ContentVersionService from "@/services/odoo/ContentVersionService";
import {lookup} from "mime-types";
import { saveAs } from "file-saver";
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
  props: {
    caseId: {
      type: String,
      required: true
    },
    externalProtocol: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapState(["window"]),
  },
  data: () => ({
    headers: [
      { text: "Comentário", value: "text" },
      { text: "Autor", value: "author" },
      { text: "Data", value: "date" }
    ],
    comments: [],
    item: {
      arquivos: []
    },
    contentDocumentIds: [],
    timeLine: [],
    fab: false,
    clientCardColor: '#595fc0',
    brokerCardColor: '#e9e9e9',
  }),
  methods: {
    ...mapMutations(["loading"]),
    ...mapGetters(["isMobileSize", "getWidth"]),
    async requestComments() {
      this.loading(true);
      await this._comentsService
        .FindById(this.caseId, "helpdesk.ticket", 'comment', 'Discussões')
        .then(res => {
          this.configureComments(res.data.records);
        })
        .finally(() => {
          this.loading(false);
        });
    },
    getCommentText(comment){
      if(!comment || comment.trim() == ""){
        return 'Sem comentários...'
      }
      return comment
    },
    configureComments(commentData) {
      if (commentData.length) {
        commentData.forEach((comment) => {
          this.comments.push({
            id: comment.id,
            text: comment.body,
            formatDate: moment(comment.create_date.replace(" ", "T") + '.000Z').format("DD/MM"),
            formatHour: moment(comment.create_date.replace(" ", "T") + '.000Z').format("HH:mm"),
            showMore: false,
            hasMoreThanOneLine: this.commentHasMoreThanOneLine(comment.body),
            date: moment(comment.create_date.replace(" ", "T") + '.000Z').format("DD/MM"),
            author: comment.author_id,
            type: 'comment',
            icon: 'mdi-text',
            email: comment ? comment.email_from : null,
          })
          this.configureAttachments(comment.attachment_ids, comment.email_from)
        });
      }
    },
    configureAttachments(attachmentData, email) {
      attachmentData.forEach(attach => {
            if (this.item.arquivos.length) {
              if (this.item.arquivos.some(arquivo => arquivo.id === attach.id)) {
                return;
              }
            }
            this.item.arquivos.push({
              id: attach.id,
              name: attach && attach.name ? attach.name : '',
              extension: attach && attach.mimetype ? attach.mimetype.split("/")[1] : '',
              icon: attach && attach.mimetype ? this.convertToIcon(attach.mimetype.split("/")[1]) : '',
              downloading: false,
              formatDate: attach && attach.create_date ? moment(attach.create_date.replace(" ", "T") + '.000Z').format("DD/MM") : '',
              formatHour: attach && attach.create_date ? moment(attach.create_date.replace(" ", "T") + '.000Z').format("HH:mm") : '',
              date: attach && attach.create_date ? moment(attach.create_date.replace(" ", "T") + '.000Z').format("DD/MM") : '',
              author: '',
              email: email
            })
          }
      );
    },
    convertToIcon(fileExtension) {
      let configuredIcon = "file";
      const types = {
        word: ["doc", "dot", "webk", "docx", "docm", "dotx", "dotm", "docb"],
        excel: [
          "xls",
          "xlt",
          "xlm",
          "xlsx",
          "xlsm",
          "xltx",
          "xltm",
          "xlsb",
          "xla",
          "xlam",
          "xll",
          "xlw"
        ],
        powerpoint: [
          "ppt",
          "pot",
          "pps",
          "pptx",
          "pptm",
          "potx",
          "potm",
          "ppam",
          "ppsx",
          "ppsm",
          "sldx",
          "sldm"
        ],
        pdf: ["pdf"],
        csv: ["csv"],
        alt: ["txt"],
        image: ["png", "jpg", "jpeg", "svg"]
      };

      for (const typeName in types) {
        if (types[typeName].includes(fileExtension)) {
          configuredIcon = typeName;
        }
      }
      return `fas fa-file-${configuredIcon}`;
    },
    downloadFile(file) {
      this.loading(true);
      this._contentVersionService
          .getDownloadFile(file.id)
          .then(attachmentData => {
            saveAs(
                new Blob([attachmentData.data], { type: lookup(file.extension) }),
                file.name
            );
          })
          .finally(() => (this.loading(false)));
    },
    async getDocumentlinks() {
      await this._contentVersionService
          .FindAll(this.caseId, "helpdesk.ticket")
          .then(response => {
            if (
                response == null ||
                response.data == null ||
                response.data.records == null
            ) {
              return true;
            }
            return this.configureAttachments(response.data.records);
          })
    },
    generateTimeLineItens(){

      if(this.comments.length){
        Array.prototype.push.apply(this.timeLine, this.comments);
      }

      if(this.item.arquivos.length){
        let files = this.item.arquivos.map(file => ({
          id: file.id,
          text: file.name,
          date: file.date,
          author: file.author,
          type: 'file',
          icon: 'fas fa-download',
          downloading: false,
          formatDate: file.formatDate,
          formatHour: file.formatHour,
          extension: file.extension,
          name: file.name,
          email: file.email
        }));

        Array.prototype.push.apply(this.timeLine, files);
      }

      this.timeLine.sort(function(a,b){
        return (b.date > a.date) ? 1 : (b.date < a.date) ? -1 : 0;
      });

    },
    isClientItem(item){
      return item.email != this.getUserEmail();
    },
    getUserEmail() {
      return this.$auth.user() && this.$auth.user().email ? this.$auth.user().email : null;
    },
    showDate(item, index){
      if(this.timeLine[index-1] != null && this.timeLine[index-1].formatDate === item.formatDate)
          return false;
      else
          return true;
    },
    commentHasMoreThanOneLine(comment){
      return comment && (comment.indexOf('<p>') > -1 ||
              ( comment.length > 75 && this.getWidth() > 1650 &&  !this.isMobileSize()) ||
              ( comment.length > 60 && this.getWidth() <= 1650 && !this.isMobileSize()) ||
              ( comment.length > 30 && this.isMobileSize()));
    },
    getFirstLineComment(comment){
      if(!comment || comment.trim() == ""){
        return 'Sem comentários...'
      }
      let start = 0;
      let size;
      if(comment.indexOf('<p>') > -1 ) {
        size = comment.indexOf('</p>');
        start = 3;
      }else
        size = this.isMobileSize() ? 30 : this.getWidth() > 1650 ? 75 : 60;

      return comment.substring(start, size) + ' ...'
    },
    async loadCommentsAndAttachments(){
      this._comentsService = new ComentsService();
      this._contentVersionService = new ContentVersionService();

      await this.requestComments();
      if(this.externalProtocol)
        await this.getDocumentlinks();

    }
  },
  async created() {
    await this.loadCommentsAndAttachments().then(res => {
      this.generateTimeLineItens();
    })
  }
};
</script>
<style>

.v-application p{
  margin-bottom: 0;
}

</style>
